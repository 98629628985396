<template>
  <div class="container">

    <v-dialog v-model="dialog" max-width="600" persistent>
      <v-card>
        <v-card-title class="headline">
          Personal Details detected
        </v-card-title>

        <v-card-text>
          IP-Bid is designed as a confidential advertising service. For data privacy and user confidentiality, we recommend personal details are excluded.
        </v-card-text>

        <v-card-text>
          Would you like the data below to be removed automatically for you?
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="info" text @click="clearContactDetailsFromDescriptionAndSaveChanges">
            Yes
          </v-btn>

          <v-btn color="info" text @click="dismissContactDetailsDialogAndSaveChanges">
            No
          </v-btn>
        </v-card-actions>

        <v-card-text v-for="contactDetail in contactDetailsErrors" :key="contactDetail">
          {{contactDetail}}
        </v-card-text>


      </v-card>
    </v-dialog>

    <v-btn
      tile
      small
      color="primary"
      text
      :to="{
        name: 'PostDetail'
      }"
      ><v-icon>mdi-chevron-left</v-icon> to post</v-btn
    >
    <h2>Amend Post Description</h2>
    <span style="color: grey; fontSize: 0.8em"
                >For data privacy and confidentiality, we recommend personal details to be excluded.</span
              >

    <v-row style="background: white; borderRadius: 3px" class="my-2 mx-1 pa-4">
      <v-col cols="12" md="8">
        <div class="v-text-field__details" v-if="errorMessage">
          <div class="v-messages theme--light error--text" role="alert">
            <div class="v-messages__wrapper">
              <div class="v-messages__message">
                {{ errorMessage }}
              </div>
            </div>
          </div>
        </div>
        <wysiwyg v-model="description" />
      </v-col>
    </v-row>
    <v-btn
      tile
      color="secondary"
      :loading="loading"
      @click.prevent="saveChangesClick"
      >Save Changes</v-btn
    >
  </div>
</template>
<style>
@import "~vue-wysiwyg/dist/vueWysiwyg.css";
</style>
<script>
import { mapGetters } from "vuex";
export default {
  name: "AmendPostDescription",
  metaInfo() {
    return {
      title: "IP-BID.com - Amend Posting Description"
    };
  },
  data() {
    return {
      dialog: false,
      dialogDismissed: false,
      descriptionWhenDialogDismissed: null,
      description: "",
      successMessage: null,
      errorMessage: null,
      contactDetailsErrors: [],
      loading: false
    };
  },
  async created() {
    this.loading = true;
    if (
      this.loadedPost === null ||
      this.loadedPost.businessId !== this.$route.params.id
    ) {
      await this.$store.dispatch("retrievePostByID", this.$route.params.id);
    }

    this.description = this.loadedPost.description;
    this.loading = false;
  },
  computed: {
    ...mapGetters(["loadedPost"])
  },
  methods: {
    async saveChangesClick() {
      if (!this.description) {
        this.errorMessage = "Description is required";
        return;
      }

      this.loading = true;

      const payload = {
        id: this.$route.params.id,
        description: this.description
      };

      if (this.dialogDismissed && this.descriptionWhenDialogDismissed === this.description) {
        await this.saveChanges();
      } else {
        await this.$store
        .dispatch("checkDescription", payload.description)
        .then(response => {
          if (response.contactDetailsDetected) {
            this.contactDetailsErrors = response.contactDetails;
            this.dialog = true;
          } else {
            this.contactDetailsErrors = [];
            this.dialog = false;

            this.saveChanges();
          }
        })
        .catch(e => {
          this.errorMessage = e.response;
        });
      }

        this.loading = false;
    },
    async saveChanges() {
      const payload = {
        id: this.$route.params.id,
        description: this.description
      };

      await this.$store
            .dispatch("amendPostDescription", payload)
            .then(response => {
              response;
              this.$router.push({
                name: "PostDetail"
              });
            })
            .catch(e => {
              this.errorMessage = e.response;
            });
    },
    async dismissContactDetailsDialogAndSaveChanges() {
      this.dialog = false;
      this.dialogDismissed = true;
      this.descriptionWhenDialogDismissed = this.description;

      await this.saveChanges();
    },
    async clearContactDetailsFromDescriptionAndSaveChanges() {
      for (let index = 0; index < this.contactDetailsErrors.length; index++) {
        const element = this.contactDetailsErrors[index];
        this.description = this.description.replaceAll(element, "");
      }
      await this.dismissContactDetailsDialogAndSaveChanges();
    }
  }
};
</script>
