import Vue from "vue";
import App from "./App.vue";
import VueMeta from "vue-meta";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import Repository from "@/store/Services/Repository.js";
import axios from "axios";
import VueAnalytics from "vue-analytics";
import Notifications from 'vue-notification'

Vue.config.productionTip = false;

import wysiwyg from "vue-wysiwyg";
Vue.use(wysiwyg, {
  hideModules: { "code": true, "image" : true, "link": true },

}); // config is optional. more below
//Catch Access Token Expiry and Refresh it if it has resubmitting original request
Repository.interceptors.response.use(
  response => response,
  e => {
    const refreshToken = localStorage.getItem("refresh_token");
    if (e.response.status !== 401 || !refreshToken) {
      return Promise.reject(e);
    } else if (e.response.status === 401 && refreshToken) {
      store
        .dispatch("refreshToken")
        .then(response => {
          e.response.config.headers["Authorization"] =
            "Bearer " + response.data.token;

          //Prompt user to reattempt if they are creating a new post from scratch
          if (
            router.history.current.name === "PostWithoutDraft" &&
            e.config.url == "seller/draft"
          ) {
            store.dispatch("setTryAgainMessage");

            return;
          }

          //Resend Failed Request
          axios(e.response.config)
            .then(resentResponse => {
              //redirect if failed to post a draft as to not create duplicates
              if (
                router.history.current.name === "AddPost" &&
                e.response.config.url === "seller/draft"
              ) {
                const postId = resentResponse.data.businessId;
                router.push({
                  name: "PostDetail",
                  params: { id: postId }
                });
              }
            })
            .catch();
        })
        .catch(refreshError => {
          router.push({ name: "Login", query: { reason: "expired" } });
          return Promise.reject(refreshError);
        });
    }
    return Promise.reject(e);
  }
);

Vue.use(VueMeta, {
  keyName: "metaInfo",
  attribute: "data-vue-meta",
  tagIDKeyName: "vmid",
  refreshOnceOnNavigation: false
});
Vue.use(VueAnalytics, {
  id: "UA-172659319-1",
  router
});

Vue.use(Notifications);

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");
